import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/SEO"
import HeaderCongress from "../components/headerCongress"
import "../styles/landing-campaign.scss"
import "../styles/contacto.scss"
import { IconContext } from "react-icons"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import { Link } from "gatsby"

export default function CyberSummit(props) {
  const [nombre, setNombre] = useState("")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState("")
  const [mensaje, setMensaje] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [mensajeError, setMensajeError] = useState("")
  const [respuesta, setRespuesta] = useState("")

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async e => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("leadsciberresiliency")
    //--> grab the generated token by the reCAPTCHA
    // Prepare the data for the server, specifically body-parser

    const data = JSON.stringify({ nombre, email, telefono, mensaje, result })
    // POST request to your server

    fetch(`${process.env.API_URL}/securitycongress-2023-s`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data, //--> this contains your data
    })
      .then(res => res.json())
      .then(data => {
        const success = data.success
        const score = data.score

        if (success && score >= 0.7) {
          setMensajeError("Gracias por ponerte en contacto con nosotros")
          setRespuesta("correcto")
          setNombre("")
          setEmail("")
          setTelefono("")
          setMensaje("")
          setIsChecked(false)
        } else {
          setMensajeError(
            "Ha habido un error en el envío del formulario, por favor inténtalo de nuevo"
          )
          setRespuesta("error")
        }
      })
  }

  return (
    <Layout>
      <Seo
        title="bullhost threat's over experience"
        pathname={props.location.pathname}
        robots="index,follow"
        imagen={`${props.location.origin}/img/landings/threats-over-experience/portada-threats-over-1280.webp`}
      />
      <div className="landing-sin-fixed">
        <div className="cabecera-block">
          <div
            className="cabecera-campaign-dos-col threats"
          >
            <div className="texto">
              <h1>bullhost Threat's Over Experience</h1>
              <h2 className="block">13 de diciembre 2023</h2>
              {/* <hr className="separador" /> */}
              <h2 style={{ marginBottom: 0, fontWeight: 200 }}>Hotel Embarcadero</h2>

              <div className="hero-ponente">
                <p>
                  <strong>Avenida Zugazarte, 51. 48930, Las Arenas - Getxo (Bizkaia)</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="intro-campaign congress">
          <img src="/img/bullhost-white.svg" alt="Logo de Bullhost"></img>
          <img
            src="/img/landings/threats-over-experience/logpoint-logo-white-dark.png"
            alt="Logo de Logpoint"
          ></img>
          <img
            src="/img//landings/threats-over-experience/logo_ajoomal_blanco_sin_fondo.png"
            alt="Logo de AjoomalAsociados"
          ></img>
        </div>
        <div className="congress">
          <div className="cuerpo-servicio" style={{ overflow: "hidden" }}>
            {/*BLOQUE TEXTO*/}
            <section className="limitador contenido__servicio pad-congress">
              <div id="grid-entradas">
                <div className="row">
                  <h2>
                    Dale el ultimátum a las ciber amenazas y responde con firmeza.
                  </h2>
                  <p>
                    Descubre nuestra solución integral de ciberseguridad diseñada para esquivar cualquier amago de acometidas, en un mundo golpeado por ciberataques y con las ciber amenazas a flor de piel. Conoce el arte de la respuesta a través de una herramienta que ofrece más prestaciones que un SIEM convencional.
                  </p>
                  <p>
                    <b>El día 13 de diciembre</b> exploraremos junto a Logpoint (líder europeo en soluciones de automatización y respuesta ante amenazas) y Ajoomal (mayorista de valor añadido especializado en soluciones de seguridad IT) en las últimas tendencias en automatización de procesos de control de seguridad para la detección y respuesta ante amenazas bajo el criterio del estándar de NIS2. Aterrizando una demostración para la previsión, resolución y evasión de amenazas complejas de ciberseguridad. Traduciendo el expertise necesario para la seguridad de tu organización.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Reserva ya tu lugar en Bullhost Threat's over experience y consigue el conocimiento más innovador en respuesta ante amenazas del mercado.
                  </p>
                </div>
              </div>
            </section>
            {/* FIN BLOQUE TEXTO */}
            {/*BLOQUE FOTO*/}
            <section className="limitador contenido__servicio foto-mujer pad-congress">
              <div id="grid-entradas">
                <div className="row">
                  <img
                    src="/img/landings/security-congress/empresaria-mirando-presentacion-interactiva.webp"
                    alt="Empresaria mirando una presentación interactiva"
                  ></img>
                  <h2>
                    Protege tu empresa contra ciberamenazas: descubre las
                    soluciones más avanzadas en ciberseguridad
                  </h2>
                </div>
              </div>
            </section>
            {/* FIN BLOQUE FOTO */}
            {/*BLOQUE HORARIO*/}
            <section className="bloque-horario">
              <div className="blur">
                <div className="ellipse horario"></div>
              </div>
              <div className="header-inicio limitador-inicio">
                <h2>Agenda del evento</h2>
              </div>
              <section className="limitador contenido__servicio congress">
                <div className="prueba-demo">
                  <div className="cuerpo-contacto bloque-noasistir-div congress">
                    <div className="caja-formulario congress">
                      <div className="tabla-horario">
                        <div className="tabla-horario_row">
                          <span className="hora">Horario</span>
                        </div>
                        <div className="tabla-horario_row">
                          <span className="hora">11:45 · </span>
                          <div className="descripcion">
                            <span className="actividad">
                              Café bienvenida
                            </span>
                          </div>
                        </div>
                        <div className="tabla-horario_row">
                          <span className="hora">12:00 · </span>
                          <div className="descripcion">
                            <span className="actividad">
                              Presentación / Introducción evento
                            </span>
                            <span className="ponente">| bullhost</span>
                          </div>
                        </div>
                        <div className="tabla-horario_row">
                          <span className="hora">12:15 · </span>
                          <div className="descripcion">
                            <span className="actividad">
                              Más que un SIEM
                            </span>
                            <span className="ponente">| LogPoint</span>
                          </div>
                        </div>
                        <div className="tabla-horario_row">
                          <span className="hora">12:45 · </span>
                          <div className="descripcion">
                            <span className="actividad">
                              NIS2
                            </span>
                            <span className="ponente">| Ajoomal</span>
                          </div>
                        </div>
                        <div className="tabla-horario_row">
                          <span className="hora">13:15 · </span>
                          <div className="descripcion">
                            <span className="actividad">
                              Demo SIEM
                            </span>
                            <span className="ponente">| bullhost</span>
                          </div>
                        </div>
                        <div className="tabla-horario_row">
                          <span className="hora">14:00 · </span>
                          <div className="descripcion">
                            <span className="actividad">Aperitivo | Networking</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
            {/*FIN BLOQUE HORARIO*/}

            {/* SECCION FORMULARIO */}
            <section className="limitador contenido__servicio">
              <div className="blur">
                <div className="ellipse contacto"></div>
              </div>
              <h2>¿Quieres acudir?</h2>
              <div className="bloque-noasistir">
                <div className="prueba-demo">
                  <div className="cuerpo-contacto bloque-noasistir-div congress">
                    <div className="caja-formulario">
                      <h3>Quiero apuntarme</h3>
                      <form
                        className="form-contacto"
                        method="post"
                        autoComplete="off"
                        onSubmit={handleSubmit}
                      >
                        <label htmlFor="nombre">Nombre</label>
                        <input
                          name="nombre"
                          placeholder="Nombre*"
                          required
                          value={nombre}
                          onChange={e => setNombre(e.target.value)}
                        />

                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email*"
                          required
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />

                        <label htmlFor="telefono">Teléfono</label>
                        <input
                          type="tel"
                          name="telefono"
                          placeholder="Teléfono*"
                          maxLength="14"
                          minLength="9"
                          required
                          value={telefono}
                          onChange={e => setTelefono(e.target.value)}
                        />

                        <textarea
                          name="mensaje"
                          id="mensaje"
                          placeholder="¿Quieres darnos algo de info?"
                          cols="30"
                          rows="5"
                          value={mensaje}
                          onChange={e => setMensaje(e.target.value)}
                        ></textarea>

                        <div className="caja-checkbox">
                          <input
                            type="checkbox"
                            id="privacidad"
                            name="privacidad"
                            // defaultChecked={isChecked}
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                          />
                          <label htmlFor="privacidad" className="label-check">
                            He leído y acepto la{" "}
                            <Link to="/politica-de-privacidad/">
                              política de privacidad
                            </Link>
                          </label>
                        </div>

                        <div
                          className={`mensaje-formulario
                                ${respuesta}                              
                            `}
                          dangerouslySetInnerHTML={{ __html: mensajeError }}
                        />

                        <IconContext.Provider value={{ color: "#1f1f1f" }}>
                          <input
                            type="submit"
                            value="REGISTRARME"
                            className="boton-enviar"
                            disabled={!isChecked}
                          />
                        </IconContext.Provider>
                        <p className="texto-recaptcha">
                          Este sitio está protegido por reCAPTCHA y se aplican la{" "}
                          <a href="https://policies.google.com/privacy">
                            política de privacidad
                          </a>{" "}
                          y los{" "}
                          <a href="https://policies.google.com/terms">
                            términos del servicio
                          </a>{" "}
                          de Google.
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}
